<template>
  <div>
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{'mt-5': +i !== 0}"
           class="navy-blue-border-1px border-15px"
      >
      <div style="overflow-x: auto !important;">
        <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('sample.to_who') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Sample', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_yarn_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.dispatch_location_id"
                :name="`order_fabric_ready_made_dispatch_location_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
                :not-list="true"
            ></custom-multi-select>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <input
                v-model="currentDetail.to_who"
                :name="`order_fabric_ready_made_to_who_${index}`"
                :placeholder="$t('sample.to_who')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="text"
            >
          </div>
        </div>
      </div>
        
        <div style="overflow-x: auto !important;">

          <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.start_date') }}
          </div>

          <div class="flex-1 py-2 text-center">
            {{ $t('order.end_date') }}
          </div>
        </div>
        <div class="d-flex">
          <div  class="py-2 text-center col-6 top-1-items">
            <div>
              <date-picker-input
                  :title="null"
                  :required="false"
                  :name="`start_date${index}`"
                  :model.sync="model.start_date"
                  :input-classes="'w-100px'"
                  :has-bottom-margin="false"
              ></date-picker-input>
            </div>
          </div>
          <div   class="py-2 text-center col-6 top-1-items">
            <div>
              <date-picker-input
                  :title="null"
                  :required="false"
                  :name="`end_date${index}`"
                  :model.sync="model.end_date"
                  :input-classes="'w-100px'"
                  :has-bottom-margin="false"
              ></date-picker-input>
            </div>
          </div>
        </div>
        </div>

        
        <div style="overflow-x: auto !important;">
          <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.amount') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.unit') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.currency_unit') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.exchange_rate') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.vade') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.unit_price') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.total_price') }}
          </div>
        </div>
        <div class="d-flex">
          <div
              class="py-2 text-center col-6 top-1-items">
            <input
                v-model="currentDetail.amount"
                :class="{'is-invalid': !+currentDetail.amount}"
                :name="`order_fabric_ready_made_amount_${index}`"
                :placeholder="$t('order.please_enter_quantity')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="number"
            >
          </div>
          <div
              class="py-2 text-center col-6 top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.amount_unit_id"
                :name="`amount_unit_id_${index}`"
                :not-list="true"
                :options="amountUnitOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div
              class="py-2 text-center col-6 top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.currency_unit_id"
                :name="`currency_unit_id_${index}`"
                :not-list="true"
                :options="currencyOptions"
                :required="false"
                :title="null"
                size="sm"
                @save="onExpenseDetailCurrencyChange($event, index)"
            ></custom-multi-select>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ currentDetail.exchange_rate }}
          </div>
          <div
              class="py-2 text-center col-6 top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.expiry_id"
                :name="`expiry_${index}`"
                :not-list="true"
                :options="expirationTypeOptions"
                :required="false"
                :title="null"
                object-key="name"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.price"
                  :class="{'is-invalid': !+currentDetail.price}"
                  :name="`price_${index}`"
                  :placeholder="$t('order.please_enter_unit_price')"
                  :required="false"
                  class="form-control form-control-sm"
                  min="0"
                  step="0.01"
                  type="number"
              />
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ totalAmount(index) }} {{ currencyOptions[currentDetail.currency_unit_id] }}
          </div>
        </div>
        </div>

        

        <div class="d-flex txt-area">
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_ready_made_description_${index}`"
                :required="false"
                :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })">

      </span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })"
      ></span>

      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :send-mail-url="sendMailUrl(index)"
          name="accessory-request"
          :show-repeat-button="true"
          @addNewDetail="addNewDetail"
          @onClickRepeatRequest="onClickRepeatRequest(currentDetail.id, index)"
          @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
          @submit="submitFabricReadyMadeRequest (index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TestMethodRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/CurrencyTableRow";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import DateDetailTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/samples/layout/request-fabric-accessory/layout/BottomButtons";
import moment from "moment";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import {CREATE_ITEM, DELETE_ITEM_BY_ID} from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricReadyMadeTypeTable",
  components: {
    BottomButtons,
    DateDetailTableRow,
    TextareaInput,
    CurrencyTableRow,
    TestMethodRow,
    DatePickerInput,
    CustomMultiSelect
  },
  props: {
    model: {
      required: true,
    },
    defaultModel: {
      required: true,
    },
    order: {
      required: true,
      default: null
    },
    orderFabricId: {
      required: true,
    },
    currencyOptions: {
      required: true
    },
    amountUnitOptions: {
      required: true
    },
    currencyOptionsRaw: {
      required: true
    },
    supplierCompanyOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: false,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    }
  },
  data() {
    return {
      is_submitted: false,
    }
  },
  methods: {
    onExpenseDetailCurrencyChange(payload, index) {
      let newValue = payload.newValue, oldValue = payload.oldValue;
      let rate = 1;
      this.modelProxy[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.currencyOptions) {
        rate = Number(this.currencyRateOptions[this.currencyOptions[newValue[0]]].rate);
      }
      this.modelProxy[index].exchange_rate = rate;
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/accessory-request/${self.orderFabricId}/accessory-request-information/${itemId}`,
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            let defaultModelClone = _.cloneDeep(self.defaultModel);
            defaultModelClone.is_editing = false;
            defaultModelClone.is_submitted = false;
            self.modelProxy = [defaultModelClone];
          }
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    onClickRepeatRequest(itemId, index) {
      let self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/accessory-requests/${itemId}/copy`,
      }).then(result => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_created')).then(_ => {
            self.$router.replace({name: 'sample.task.index'}).catch(err => {
            })
          })
        }
      })
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.id = null;

      this.sweetAlertLoading();
      this.$emit("getIncomingFabricAmount");
      setTimeout(() => {
        this.modelProxy.push(copy);
        this.sweetAlertClose();
      }, 1000);
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];

      item.fabric_request_id = self.orderFabricId;
      item.start_date = moment(item.start_date).format(LARAVEL_DATE_FORMAT);
      item.end_date = moment(item.end_date).format(LARAVEL_DATE_FORMAT);

      items.push(item);

      return items;
    },
    submitFabricReadyMadeRequest(index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/accessory-request/${self.orderFabricId}/accessory-request-information`,
        contents: {
          accessory_request_informations: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      });
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    },
    totalAmount() {
      return function (index) {
        if (!this.modelProxy[index].amount) {
          return 0;
        }
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * this.modelProxy[index].price;
        return (+this.modelProxy[index].total_amount).toFixed(0) || 0 ;
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;
        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return false;
          if (!current.dispatch_location_id) return false;
          if (!+current.amount) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.price) return false;
          if (!+current.amount_unit_id) return false;
          if (!current.description) return false;
          return true;
        }

        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/accessory-request/${this.orderFabricId}/accessory-request-information/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/generalLayoutFabricType.scss";
/*Mobile End*/
</style>
