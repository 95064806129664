<template>
  <div>
    <div class="d-flex py-2 text-center  top-1-items justify-content-center py-2">
      <h4 class="d-block text-center">{{ $t('order.test_method').toUpperCase() }}</h4>
    </div>

    <div class="pt-2 text-center mx-0 w-100 row top-1-items" style="overflow-x: auto !important;">
      <div class="py-2 text-center col-6 top-1-items"
           v-for="(testMethodOption, key, index) in testMethodOptions"
           :key="`order_fabric_knit_fabric_test_method_${key}`">
        <div class="d-block" :class="{'text-danger': selectedTestMethodsProxy === undefined ? true: isRequired && selectedTestMethodsProxy.length === 0}">
          {{ testMethodOption }}
        </div>
        <div class="d-block">
          <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary pl-0">
            <input type="checkbox"
                   :value="+key"
                   :checked="selectedTestMethodsProxy.includes(+key)"
                   :name="`order_fabric_knit_fabric_test_method_${index}`"
                   v-model="selectedTestMethodsProxy">
            <span style="border-radius: 8px;"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestMethodRow",
  props: {
    testMethodOptions: {
      required: true,
      type: Object,
    },
    selectedTestMethods:{
      required: true,
    },
    productProcesses: {
      required: true,
    },
    isRequired: {
      required: false,
      default: true,
    }
  },
  computed: {
    selectedTestMethodsProxy: {
      set(value){
        this.$emit('update:selectedTestMethods', value);
        this.$emit('update:productProcesses', value.map(value => {
          return {
            id: value
          };
        }));
      },
      get(){
        if(this.selectedTestMethods === undefined) return [];
        return this.selectedTestMethods;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.checkbox.checkbox-primary > input:checked ~ span {
  background-color: #fff !important;
}

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span::after {
  border-color: $primary;
}

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span {
  background-color: #fff;
  border-color: #d6d6e0;
}

.checkbox > span {
  position: relative;
  margin-left: -9px !important;
}
</style>
<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/generalLayoutFabricType.scss";
/*Mobile End*/
</style>