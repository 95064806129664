<template>
  <div class="card card-custom">
    <div class="card-header py-7">
      <h3>{{ $t("editSample.please_select_image_to_upload") }}</h3>
    </div>
    <div class="card-body card-body-scroll" style="overflow-y: scroll; max-height: 65vh">
      <div class="">
        <div class="">
          <div class="w-100">
            <div class="w-100 d-flex justify-content-center mb-4">
              <img class="w-75px h-75px rounded-lg" style="object-fit: cover;" :src="selectedImage == null ? noImage : images[selectedImage].file_link" alt="" >
            </div>
            <p class="text-center font-weight-bold">{{ $t('editSample.product_image') }}</p>
          </div>
          <div class="row justify-items-center justify-content-center">
            <div class="cursor-pointer m-2"
                 @click="selectedImage = index"
                 v-for="(value, index) in images">
                <div
                    :class="{'selected-image': selectedImage == null ? false : index === Number(selectedImage)}"
                    style="!important; object-fit: cover;">
                  <img :src="value.file_link" alt="" class="w-150px h-150px" style="object-fit: cover;">
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end">
      <button
          @click="closeModal"
          class="btn btn-sm btn-outline-danger font-weight-bold cursor-pointer mr-5">{{ $t('editSample.close').toUpperCase() }}
      </button>
      <button
          @click="onSubmit"
          :disabled="selectedImage == null"
          class="btn btn-sm btn-outline-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SampleImageSelectorModal",
  props: {
    images: {
      type: Array,
      required: true,
    },
    selectedImage: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      noImage: '',
    }
  },
  mounted() {
    this.noImage = this.mediaBase + '/icon/no-photos.png';
  },
  methods: {
    onSubmit(){
      this.$emit("onSubmit", this.images[this.selectedImage]);
      this.$modal.hide('select-image-for-sample');
    },
    closeModal(){
      this.$modal.hide('select-image-for-sample');
    }
  }
}
</script>

<style scoped>
  .selected-image {
    border: 3px solid #462985;
  }
</style>
