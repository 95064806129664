<template>
  <div class="d-flex justify-content-center items-center flex-column">
    <video id="videoCanvas" playsinline class="video-js vjs-default-skin">
      <p class="vjs-no-js">
        To view this video please enable JavaScript, or consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video.
        </a>
      </p>
    </video>
    <br>
    <div>
      <button
          :disabled="isStartRecording" id="btnStart"
          @click.prevent="startRecording()"
      >
          <span
              v-html="getIconByKey('icons.meeting.add-image', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer',
                  style: `${isStartRecording ? 'opacity: 0.5;': ''}`
                })"
          >
        </span>
      </button>

    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import videojs from 'video.js'

import 'webrtc-adapter'
import RecordRTC from 'recordrtc'
import Record from 'videojs-record/dist/videojs.record.js'

export default {
  props: ['uploadUrl', 'model'],
  data() {
    return {
      player: '',
      retake: 0,
      isSaveDisabled: true,
      isStartRecording: false,
      isRetakeDisabled: true,
      submitText: 'Submit',
      options: {
        autoplay: true,
        controls: true,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
          deviceButton: false,
          recordToggle: false,
          pipToggle: false
        },
        width: 600,
        height: 300,
        fluid: false,
        plugins: {
          record: {
            image: true,
            debug: true
          }
        }
      }
    }
  },
  mounted() {
    this.player = videojs('videoCanvas', this.options, () => {
    });

    this.player.on('deviceReady', () => {
      this.player.record().start();
    });

    this.player.on('deviceError', () => {
    });

    this.player.on('error', (element, error) => {
      console.error(error);
    });

    this.player.on('startRecord', () => {
    });

    this.player.on('stopRecord', () => {
    });

    this.player.on('finishRecord', () => {
      this.isSaveDisabled = false;
      if (Number(this.retake) === 0) {
        this.isRetakeDisabled = false;
      }
      this.$emit('update:model', this.base64toBlob(this.player.recordedData));
    });
  },
  methods: {
    base64toBlob(base64Data,) {
      base64Data = base64Data.split(':')[1];
      let temp = base64Data.split(',');
      let contentType = temp[0];
      base64Data = temp[1];
      let sliceSize = 1024;
      let byteCharacters = atob(base64Data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);

      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);

        let bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, {type: contentType});
    },
    startRecording() {
      this.player.record().getDevice();
    },
    stopRecording() {
      this.player.record().stopDevice();
    },
    retakeVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      this.retake += 1;
      this.player.record().start();
    }
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
