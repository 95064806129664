import { render, staticRenderFns } from "./KnittingAndRawFabricForm.vue?vue&type=template&id=5020d32d&"
import script from "./KnittingAndRawFabricForm.vue?vue&type=script&lang=js&"
export * from "./KnittingAndRawFabricForm.vue?vue&type=script&lang=js&"
import style0 from "./KnittingAndRawFabricForm.vue?vue&type=style&index=0&id=5020d32d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports