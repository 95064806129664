<template>
  <div>
    <div style="overflow-x: auto !important;">
      <div class="d-flex">
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.color') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.fabric_type') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.color') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.color_ok') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.weight_with_unit') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.width') }}
        </div>
      </div>
      <div class="d-flex">
        <div class="py-2 text-center col-6 top-1-items">
          <div class="px-2">
            <input type="text" :name="`fabric_type_${uid}`" v-model="modelProxy.fabric_type"
              :placeholder="$t('order.please_enter_fabric_type')" class="form-control form-control-sm"
              :class="{ 'is-invalid': !modelProxy.fabric_type }">
          </div>
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          <div class="px-2">
            <input type="text" :name="`color_${uid}`" v-model="modelProxy.color"
              :placeholder="$t('order.please_enter_color')" class="form-control form-control-sm"
              :class="{ 'is-invalid': !modelProxy.color }">
          </div>
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          <div class="px-2">
            <input type="text" :name="`color_arrow_${uid}`" v-model="modelProxy.color_ok"
              :placeholder="$t('order.please_enter_color_arrow')" class="form-control form-control-sm"
              :class="{ 'is-invalid': !modelProxy.color_ok }">
          </div>
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          <div class="px-2">
            <input type="text" :name="`weight_${uid}`" v-model="modelProxy.weight"
              :placeholder="$t('order.please_enter_weight')" class="form-control form-control-sm"
              :class="{ 'is-invalid': !modelProxy.weight }">
          </div>
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          <div class="px-2">
            <input type="text" :name="`width_${uid}`" :placeholder="$t('order.please_enter_width')"
              v-model="modelProxy.width" class="form-control form-control-sm"
              :class="{ 'is-invalid': !+modelProxy.width }">
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: auto !important;">
      <div class="d-flex">
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.quantity') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.tolerance_quantity') }}(%)
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.total_quantity') }}
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          {{ $t('order.incoming_quantity') }}
        </div>
        <div class="flex-1 py-2 text-center">
          {{ $t('order.remaining_quantity') }}
        </div>
      </div>
      <div class="d-flex">
        <div class="py-2 text-center col-6 top-1-items">
          <div class="p-2">
            <input v-model="modelProxy.amount" :class="{ 'is-invalid': !modelProxy.amount }"
              :name="`order_fabric_knit_type_amount_${uid}`" :placeholder="$t('order.please_enter_quantity')"
              :required="false" :step="1" :title="null" class="form-control form-control-sm" min="0" step="0.01"
              type="number" />
          </div>
        </div>
        <div class="py-2 text-center col-6 top-1-items">
          <div class="px-2">
            <input v-model="modelProxy.tolerance_amount" :disabled="true"
              :name="`order_fabric_knit_type_amount_tolerance_quantity_${uid}`"
              :placeholder="$t('order.please_enter_tolerance_amount')" :required="false" :step="1" :title="null"
              class="form-control form-control-sm" type="number">
          </div>
        </div>
        <div class="py-2 text-center col-6 top-1-items d-flex justify-content-center items-center">
          {{ totalAmount }}
        </div>
        <div class="py-2 text-center col-6 top-1-items"> {{ modelProxy.incoming }}</div>
        <div class="py-2 text-center col-6 top-1-items"> {{ Number(totalAmount) - Number(modelProxy.incoming) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

export default {
  name: "FabricDetailTableRow",
  components: {
    CustomMultiSelect
  },
  props: {
    model: {
      required: true
    }
  },
  data() {
    return {
      uid: uuid(),
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    totalAmount() {
      this.modelProxy.total_amount = this.modelProxy.amount * (1 + (+this.modelProxy.tolerance_amount / 100))
      return (+this.modelProxy.total_amount).toFixed(0) || 0;
    },
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/generalLayoutFabricType.scss";
/*Mobile End*/
</style>

