<template>
  <div>
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{ 'mt-5': +i !== 0 }"
      :ref="`knit_${currentDetail.id}`"
      :id="`knit_${currentDetail.id}`"
      class="border-15px">
        <div style="overflow-x: auto !important">
          <div class="d-flex">
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.supplier_company") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.dispatch_location") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("sample.knitting_form") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("sample.fein_pus") }}
            </div>
          </div>
          <div class="d-flex">
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Sample', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_knit_type_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
              ></custom-multi-select>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.dispatch_location_id"
                :name="`fabric_knit_type_dispatch_location_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
                :not-list="true"
              ></custom-multi-select>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <div class="p-2">
                <custom-multi-select
                  :options="fabricKnitTypes"
                  title=""
                  :required="false"
                  :placeholder="$t('sample.multi_select')"
                  :is-inline="false"
                  input-width="150px"
                  :model.sync="currentDetail.fabric_knit_type_id"
                  :not-list="true"
                  name="fabric_knit_type_id"
                  :min="1"
                  :max="1"
                ></custom-multi-select>
              </div>
            </div>

            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <div class="p-2">
                <text-input
                  title=""
                  :required="false"
                  :is-inline="false"
                  :is-valid="currentDetail.fein_pus ? 1 : 0"
                  :model.sync="currentDetail.fein_pus"
                ></text-input>
              </div>
            </div>
          </div>
        </div>

        <div style="overflow-x: auto !important">
          <div class="d-flex">
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.fabric_type") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.flow_date") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.end_date") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("sample.to_who") }}
            </div>
          </div>
          <div class="d-flex">
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <div>
                <input
                  v-model="currentDetail.fabric_type"
                  :class="{ 'is-invalid': !currentDetail.fabric_type }"
                  :name="`order_fabric_ready_made_fabric_type_${index}`"
                  :placeholder="$t('order.please_enter_fabric_type')"
                  :required="true"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
                />
              </div>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <div>
                <date-picker-input
                  :has-bottom-margin="false"
                  :input-classes="'w-85px'"
                  :model.sync="currentDetail.flow_date"
                  :name="`order_flow_date_${index}`"
                  :required="false"
                  :title="null"
                ></date-picker-input>
              </div>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <div>
                <date-picker-input
                  :has-bottom-margin="false"
                  :input-classes="'w-85px'"
                  :model.sync="currentDetail.end_date"
                  :name="`order_end_date_${index}`"
                  :required="false"
                  :title="null"
                ></date-picker-input>
              </div>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <input
                v-model="currentDetail.to_who"
                :name="`order_fabric_ready_made_to_who_${index}`"
                :placeholder="$t('sample.to_who')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="text"
              />
            </div>
          </div>
        </div>

        <div style="overflow-x: auto !important">
          <div class="d-flex">
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.amount") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.unit") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.currency_unit") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.exchange_rate") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.vade") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.unit_price") }}
            </div>
            <div class="col-6 py-2 text-center top-1-items">
              {{ $t("order.total_price") }}
            </div>
          </div>
          <div class="d-flex">
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <input
                v-model="currentDetail.amount"
                :class="{ 'is-invalid': !+currentDetail.amount }"
                :name="`order_fabric_ready_made_amount_${index}`"
                :placeholder="$t('order.please_enter_quantity')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="number"
              />
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.amount_unit_id"
                :name="`amount_unit_id_${index}`"
                :not-list="true"
                :options="amountUnitOptions"
                :required="false"
                :title="null"
                size="sm"
              ></custom-multi-select>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.currency_unit_id"
                :name="`currency_unit_id_${index}`"
                :not-list="true"
                :options="currencyOptions"
                :required="false"
                :title="null"
                size="sm"
                @save="onExpenseDetailCurrencyChange($event, index)"
              ></custom-multi-select>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              {{ currentDetail.exchange_rate }}
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.expiry_id"
                :name="`expiry_${index}`"
                :not-list="true"
                :options="expirationTypeOptions"
                :required="false"
                :title="null"
                object-key="name"
                size="sm"
              ></custom-multi-select>
            </div>
            <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items"
            >
              <div class="px-2">
                <input
                  v-model="currentDetail.price"
                  :class="{ 'is-invalid': !+currentDetail.price }"
                  :name="`price_${index}`"
                  :placeholder="$t('order.please_enter_unit_price')"
                  :required="false"
                  class="form-control form-control-sm"
                  min="0"
                  step="0.01"
                  type="number"
                />
              </div>
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ totalAmount(index) }}
              {{ currencyOptions[currentDetail.currency_unit_id] }}
            </div>
          </div>
        </div>

        <div class="d-flex txt-area">
          <div class="flex-1 px-2 py-3">
            <textarea-input
              :is-valid="!!currentDetail.description"
              :model.sync="currentDetail.description"
              :name="`order_fabric_yarn_fabric_description_${index}`"
              :required="false"
              :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span
        v-if="index > 0 && !currentDetail.id"
        class="cursor-pointer"
        @click="deleteNewDetail(index)"
        v-html="
          getIconByKey('icons.sample.delete_red', {
            class: 'w-20px h-20px d-inline-block mr-3 ',
          })
        "
      ></span>

      <span
        v-if="isValidToSubmit && index == modelProxy.length - 1"
        class="cursor-pointer"
        @click="addNewDetail"
        v-html="
          getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })
        "
      ></span>

      <bottom-buttons
        :emails="[]"
        :is-editing.sync="currentDetail.is_editing"
        :is-valid-to-submit="isValidToSubmit(index)"
        :model="currentDetail"
        :order-id="orderId"
        :send-mail-url="sendMailUrl(index)"
        name="yarn-request"
        @addNewDetail="addNewDetail"
        @onCancelInstruction="onCancelInstruction($event, currentDetail.id, index)"
        @submit="submitOrderFabricKnit($event, index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import TextInput from "@/assets/components/inputs/TextInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import _ from "lodash";
import CurrencyTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/CurrencyTableRow";
import TestMethodRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/TestMethodRow";
import BottomButtons from "@/view/pages/samples/layout/request-fabric-accessory/layout/BottomButtons";
import moment from "moment";
import { LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import { CREATE_ITEM, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricKnitTypeTable",
  components: {
    BottomButtons,
    TestMethodRow,
    CurrencyTableRow,
    DatePickerInput,
    CustomMultiSelect,
    TextareaInput,
    NumberInput,
    TextInput,
  },
  props: {
    model: {
      required: true,
    },
    yarnModel: {
      required: true,
      type: Array,
    },
    defaultModel: {
      required: true,
    },
    currentInstructionTypeId: {
      required: true,
    },
    orderId: {
      required: true,
      default: null,
    },
    order: {
      required: true,
      default: null,
    },
    supplierCompanyOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    expirationTypeOptions: {
      required: false,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    tolerances: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true,
    },
    productProcesses: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    pressTypeId: {
      required: false,
    },
    pressTypeTolerances: {
      required: false,
    },
    amountUnitOptions: {
      required: false,
    },
    fabricKnitTypes: {
      required: true,
    },
  },
  data() {
    return {
      is_submitting: false,
    };
  },
  methods: {
    onExpenseDetailCurrencyChange(payload, index) {
      let newValue = payload.newValue,
        oldValue = payload.oldValue;
      let rate = 1;
      this.modelProxy[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.currencyOptions) {
        rate = Number(this.currencyRateOptions[this.currencyOptions[newValue[0]]].rate);
      }
      this.modelProxy[index].exchange_rate = rate;
    },
    onCancelInstruction($event, itemId, index) {
      const self = this;
      this.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: `api/fabric-request/${self.orderFabricId}/knit-request/${itemId}`,
        })
        .then(function (response) {
          if (response.status) {
            self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
            self.modelProxy.splice(index, 1);
            if (!self.modelProxy.length) {
              self.modelProxy = [self.defaultModel];
            }
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.amount = 0;
      copy.id = null;
      this.modelProxy.push(copy);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];

      item.fabric_request_id = self.orderFabricId;
      item.press_type_id = self.pressTypeId;
      item.flow_date = moment(item.flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.end_date = moment(item.end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitOrderFabricKnit($event, index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store
        .dispatch(CREATE_ITEM, {
          url: `api/fabric-request/${self.orderId}/knit-request`,
          contents: {
            order_knit_requests: self.fixedData(index),
          },
        })
        .then((response) => {
          if (response.status) {
            let detail = self.modelProxy[index];
            let responseData = response.data[0];
            detail.id = responseData.id;
            detail.is_submitted = true;
            detail.is_editing = false;
            detail.pdf_link = responseData.pdf_link;
            detail.order_fabric_excel_link = responseData.order_fabric_excel_link;
            self.modelProxy[index] = detail;
            self.sweetAlertSuccess(
              self.$t(
                self.is_editing
                  ? "general.successfully_updated"
                  : "general.successfully_created"
              )
            );
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
          self.sweetAlertClose();
          self.is_submitting = false;
        });
    },
  },
  mounted() {
    if (this.yarnModel && this.yarnModel.length > 0) {
      this.modelProxy.forEach((model) => {
        model.fabric_supply_type_id = this.fabricProcessingType;
        model.order_fabric_id = this.orderFabricId;
        if (!+model.supplier_company_id) {
          model.supplier_company_id = this.yarnModel[0].supplier_company_id;
        }
      });
    }
    if(this.$route.query.qr_type=="request-knits"){
      setTimeout(() => {
        let instructionsId = this.$route.query.data.id
        let element = this.$refs["knit_"+instructionsId][0]
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }, 1000);
    }
  },
  computed: {
    totalAmount() {
      return function (index) {
        if (!this.modelProxy[index].amount) {
          return 0;
        }
        this.modelProxy[index].total_amount =
          this.modelProxy[index].amount * this.modelProxy[index].price;
        return (+this.modelProxy[index].total_amount).toFixed(0) || 0;
      };
    },
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit("update:model", value);
      },
    },
    isValidToSubmit() {
      try {
        let returnData = null;

        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return false;
          if (!+current.order_fabric_id) return false;
          if (+current.fabric_knit_type_id === 0) return false;
          if (!current.dispatch_location_id) return false;
          if (!+current.amount) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.price) return false;
          if (!+current.amount_unit_id) return false;
          if (!current.description) return false;
          if (!current.fabric_type) return false;
          return true;
        };
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/fabric-request/${this.orderFabricId}/knit-request/${itemId}/share`;
        };
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          newValue.forEach((model) => {
            model.fabric_supply_type_id = this.fabricProcessingType;
            model.order_fabric_id = this.orderFabricId;
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/generalLayoutFabricType.scss";
/*Mobile End*/
</style>
