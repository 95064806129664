<template>
  <div>
    <div class="d-flex" style="overflow-x: auto !important;">
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.currency_unit') }}
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.exchange_rate') }}
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.vade') }}
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.unit_price') }}
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.total_price') }}
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.incoming_invoice_amount') }}
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ $t('order.remaining_invoice_amount') }}
      </div>
    </div>
    <div class="d-flex">
      <div class="py-2 text-center col-6 top-1-items">
        <custom-multi-select
            :options="currencyOptions"
            :title="null"
            :item-per-row="2"
            :model.sync="model.currency_unit_id"
            :name="`currency_unit_id_${uid}`"
            :max="1"
            :not-list="true"
            :required="false"
            size="sm"
            :has-bottom-margin="false"
        ></custom-multi-select>
      </div>
      <div class="py-2 text-center col-6 top-1-items">{{ model.exchange_rate }}</div>
      <div class="py-2 text-center col-6 top-1-items">
        <custom-multi-select
            :options="expirationTypeOptions"
            :title="null"
            :item-per-row="2"
            :model.sync="model.expiry_id"
            :name="`expiry_${uid}`"
            :max="1"
            object-key="name"
            :not-list="true"
            :required="false"
            size="sm"
            :has-bottom-margin="false"
        ></custom-multi-select>
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        <div class="px-2">
          <input
              class="form-control form-control-sm"
              type="number"
              step="0.01"
              min="0"
              :placeholder="$t('order.please_enter_unit_price')"
              v-model="model.unit_price"
              :name="`unit_price_${uid}`"
              :required="false"
              :class="{'is-invalid': !+model.unit_price}"
          />
        </div>
      </div>
      <div class="py-2 text-center col-6 top-1-items">
        {{ totalPrice }} {{ currencyOptions[model.currency_unit_id] }}
      </div>
      <div class="flex-1 py-2 text-center">
      </div>
      <div class="flex-1 py-2 text-center"></div>
    </div>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { v4 as uuid } from 'uuid'
export default {
  name: "CurrencyTableRow",
  components: {CustomMultiSelect},
  props: {
    model: {
      required: true,
      type: Object
    },
    currencyOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    }
  },
  data(){
    return {
      uid: uuid(),
    }
  },
  methods: {
    fixExchangeRate() {
      if (this.model.currency_unit_id in this.currencyOptions) {
        let currentOption = this.currencyOptionsRaw.find(item => item.id == this.model.currency_unit_id);
        this.model.exchange_rate = this.currencyRateOptions[currentOption.code].rate;
      }
    }
  },
  computed: {
    totalPrice() {
      if('total_amount' in this.model) {
        this.model.total_price = (+this.model.total_amount * +this.model.unit_price).toFixed(2);
      }else{
        this.model.total_price = (+this.model.amount * +this.model.unit_price).toFixed(2);
      }
      return this.model.total_price;
    }
  },
  mounted() {
    this.fixExchangeRate();
  },
  watch: {
    'model.currency_unit_id'() {
      this.fixExchangeRate();
    }
  }
}
</script>

<style>
.top-1-items {
  border-right: 1px solid #78cdea;
  border-bottom: 1px solid #78cdea;
}

.top-1-items .is-valid,
.top-1-items .is-invalid,
.top-1-items .my-input, 
.top-1-items .form-control  {
  border-color: #78cdea !important;
  background-color: #f3f6f9 !important;
  min-width: 120px !important;
}

.txt-area {
display: flex;
flex-direction: column;
}
.txt-area textarea {
resize: none !important;
border-color: #78cdea !important;
background-color: #f3f6f9 !important;
text-indent: 8px !important;
}
</style>
