<template>
<div style="overflow-x: auto !important;">
  <div class="d-flex">
    <div class="py-2 text-center col-6 top-1-items" v-show="type != 1">
      {{ $t('order.raw_flow_date') }}
    </div>
    <div class="py-2 text-center col-6 top-1-items" v-show="type != 1">
      {{ $t('order.raw_end_date') }}
    </div>
    <div class="py-2 text-center col-6 top-1-items">
      {{ $t('order.painted_flow_date') }}
    </div>
    <div class="py-2 text-center col-6 top-1-items">
      {{ $t('order.painted_end_date') }}
    </div>
  </div>
  <div class="d-flex">
    <div v-if="type != 1" class="py-2 text-center col-6 top-1-items">
      <div>

        <date-picker-input
            :title="null"
            :required="false"
            :name="`raw_flow_date${uid}`"
            :model.sync="model.raw_flow_date"
            :input-classes="'w-100px'"
            :has-bottom-margin="false"
        ></date-picker-input>
      </div>
    </div>
    <div v-if="type != 1" class="py-2 text-center col-6 top-1-items">
      <div>
        <date-picker-input
            :title="null"
            :required="false"
            :name="`raw_end_date${uid}`"
            :model.sync="model.raw_end_date"
            :input-classes="'w-100px'"
            :has-bottom-margin="false"
        ></date-picker-input>
      </div>
    </div>
    <div class="py-2 text-center col-6 top-1-items">
      <div>
        <date-picker-input
            :title="null"
            :required="false"
            :name="`painted_flow_date${uid}`"
            :model.sync="model.painted_flow_date"
            :input-classes="'w-100px'"
            :has-bottom-margin="false"
        ></date-picker-input>
      </div>
    </div>
    <div class="py-2 text-center col-6 top-1-items">
      <div class="py-1">
        <date-picker-input
            :title="null"
            :required="false"
            :name="`painted_end_date${uid}`"
            :model.sync="model.painted_end_date"
            :input-classes="'w-100px'"
            :has-bottom-margin="false"
        ></date-picker-input>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import {v4 as uuid} from 'uuid';

export default {
  name: "DateDetailTableRow",
  components: {DatePickerInput},
  props: {
    type:{
      required: false,

    },
    model: {
      required: true,
      type: Object,
    }
  },
  data(){
    return {
      uid: uuid(),
    }
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/generalLayoutFabricType.scss";
/*Mobile End*/
</style>
