<template>
  <div>
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{ 'mt-5': +i !== 0 }"
      :ref="`paint_${currentDetail.id}`"
      :id="`paint_${currentDetail.id}`"
      >
        <div style="overflow-x: auto !important">
          <div class="d-flex">
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.supplier_company") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.dispatch_location") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.processes") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.to_who") }}
            </div>
          </div>
          <div class="d-flex">
            <div class="py-2 text-center col-6 top-1-items">
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Sample', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_paint_type_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
              ></custom-multi-select>
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.dispatch_location_id"
                :name="`fabric_knit_type_dispatch_location_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
                :not-list="true"
              >
              </custom-multi-select>
            </div>

            <div class="py-2 text-center col-6 top-1-items">
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max-show="1"
                :model.sync="currentDetail.fabric_process_ids"
                :name="`order_fabric_paint_type_fabric_process_${index}`"
                :options="fabricProcessOptions"
                :required="false"
                :title="null"
                size="sm"
              ></custom-multi-select>
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              <input
                v-model="currentDetail.to_who"
                :name="`order_fabric_ready_made_to_who_${index}`"
                :placeholder="$t('sample.to_who')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="text"
              />
            </div>
          </div>

          <div style="overflow-x: auto !important">
            <div class="d-flex">
              <div class="py-2 text-center col-6 top-1-items">
                {{ $t("sample.fabric_type") }}
              </div>
              <div class="py-2 text-center col-6 top-1-items">
                {{ $t("order.color_ok") }}
              </div>
            </div>
            <div class="d-flex">
              <div class="py-2 text-center col-6 top-1-items">
                <input
                  v-model="currentDetail.fabric_type"
                  :class="{ 'is-invalid': !currentDetail.fabric_type }"
                  :name="`order_fabric_ready_made_fabric_type_${index}`"
                  :placeholder="$t('order.please_enter_fabric_type')"
                  :required="true"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
                />
              </div>
              <div class="py-2 text-center col-6 top-1-items">
                <input
                  v-model="currentDetail.color_ok"
                  :class="{ 'is-invalid': !currentDetail.color_ok }"
                  :name="`order_fabric_ready_made_color_ok_${index}`"
                  :placeholder="$t('order.color_ok')"
                  :required="true"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>

        <date-detail-table-row :model="currentDetail"></date-detail-table-row>

        <test-method-row
          :product-processes.sync="currentDetail.product_processes"
          :selected-test-methods.sync="currentDetail.selectedProductProcesses"
          :test-method-options="productProcesses"
        >
        </test-method-row>
        <div style="overflow-x: auto !important">
          <div class="d-flex">
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.amount") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.unit") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.price") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.currency_unit") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.exchange_rate") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.vade") }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ $t("order.total_price") }}
            </div>
          </div>
          <div class="d-flex">
            <div class="py-2 text-center col-6 top-1-items">
              <input
                v-model="currentDetail.amount"
                :class="{ 'is-invalid': !+currentDetail.amount }"
                :name="`order_fabric_ready_made_amount_${index}`"
                :placeholder="$t('order.please_enter_quantity')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="number"
              />
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.amount_unit_id"
                :name="`amount_unit_id_${index}`"
                :not-list="true"
                :options="amountUnitOptions"
                :required="false"
                :title="null"
                size="sm"
              ></custom-multi-select>
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              <div class="px-2">
                <input
                  v-model="currentDetail.price"
                  :class="{ 'is-invalid': !+currentDetail.price }"
                  :name="`price_${index}`"
                  :placeholder="$t('order.please_enter_price')"
                  :required="false"
                  class="form-control form-control-sm"
                  min="0"
                  step="0.01"
                  type="number"
                />
              </div>
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.currency_unit_id"
                :name="`currency_unit_id_${index}`"
                :not-list="true"
                :options="currencyOptions"
                :required="false"
                :title="null"
                size="sm"
                @save="onExpenseDetailCurrencyChange($event, index)"
              ></custom-multi-select>
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              {{ currentDetail.exchange_rate }}
            </div>
            <div class="py-2 text-center col-6 top-1-items">
              <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.expiry_id"
                :name="`expiry_${index}`"
                :not-list="true"
                :options="expirationTypeOptions"
                :required="false"
                :title="null"
                object-key="name"
                size="sm"
              >
              </custom-multi-select>
            </div>

            <div class="py-2 text-center col-6 top-1-items">
              {{ totalAmount(index) }}
              {{ currencyOptions[currentDetail.currency_unit_id] }}
            </div>
          </div>
        </div>

        <div class="d-flex txt-area">
          <div class="flex-1 px-2 py-3">
            <textarea-input
              :is-valid="!!currentDetail.description"
              :model.sync="currentDetail.description"
              :name="`order_fabric_yarn_fabric_description_${index}`"
              :required="false"
              :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span
        v-if="index > 0 && !currentDetail.id"
        class="cursor-pointer"
        @click="deleteNewDetail(index)"
        v-html="
          getIconByKey('icons.sample.delete_red', {
            class: 'w-20px h-20px d-inline-block mr-3 ',
          })
        "
      ></span>

      <span
        v-if="isValidToSubmit && index == modelProxy.length - 1"
        class="cursor-pointer"
        @click="addNewDetail"
        v-html="
          getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })
        "
      ></span>

      <bottom-buttons
        :emails="[]"
        :is-editing.sync="currentDetail.is_editing"
        :is-valid-to-submit="true"
        :model="currentDetail"
        :order-id="orderId"
        :send-mail-url="sendMailUrl(index)"
        name="paint-request"
        @addNewDetail="addNewDetail"
        @onCancelInstruction="onCancelInstruction($event, currentDetail.id, index)"
        @submit="submitOrderFabricPaint($event, index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import CurrencyTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/CurrencyTableRow";
import TestMethodRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/TestMethodRow";
import _ from "lodash";
import DateDetailTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/samples/layout/request-fabric-accessory/layout/BottomButtons";
import moment from "moment";
import { LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import { CREATE_ITEM, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";
import FollowingExportTasks from "@/view/pages/following-export/tasks/FollowingExportTasks";

export default {
  name: "OrderFabricPaintTypeTable",
  components: {
    BottomButtons,
    DateDetailTableRow,
    TestMethodRow,
    CurrencyTableRow,
    TextareaInput,
    DatePickerInput,
    CustomMultiSelect,
  },
  props: {
    model: {
      required: true,
    },
    previousModel: {
      required: true,
    },
    yarnModel: {
      required: true,
      type: Array,
    },
    defaultModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null,
    },
    order: {
      required: true,
      default: null,
    },
    currentInstructionTypeId: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    tolerances: {
      required: true,
    },
    productProcesses: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    amountUnitOptions: {
      required: true,
    },
    defaultColor: null,
  },
  data() {
    return {
      is_submitting: false,
    };
  },
  methods: {
    onExpenseDetailCurrencyChange(payload, index) {
      let newValue = payload.newValue,
        oldValue = payload.oldValue;
      let rate = 1;
      this.modelProxy[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.currencyOptions) {
        rate = Number(this.currencyRateOptions[this.currencyOptions[newValue[0]]].rate);
      }
      this.modelProxy[index].exchange_rate = rate;
    },
    onCancelInstruction($event, itemId, index) {
      const self = this;
      this.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: `api/fabric-request/${self.orderFabricId}/paint-request/${itemId}`,
        })
        .then(function (response) {
          if (response.status) {
            self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
            self.modelProxy.splice(index, 1);
            if (!self.modelProxy.length) {
              self.modelProxy = [self.defaultModel];
            }
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.amount = 0;
      copy.id = null;

      this.modelProxy.push(copy);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];
      item.fabric_supply_type_id = Number(this.fabricProcessingType);
      item.fabric_processes = item.fabric_process_ids.map((process) => {
        return {
          id: process,
        };
      });

      item.fabric_request_id = self.orderFabricId;
      item.raw_flow_date = moment(item.raw_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.raw_end_date = moment(item.raw_end_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_flow_date = moment(item.painted_flow_date).format(
        LARAVEL_DATE_TIME_FORMAT
      );
      item.painted_end_date = moment(item.painted_end_date).format(
        LARAVEL_DATE_TIME_FORMAT
      );

      items.push(item);

      return items;
    },
    submitOrderFabricPaint($event, index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store
        .dispatch(CREATE_ITEM, {
          url: `api/fabric-request/${self.orderId}/paint-request`,
          contents: {
            order_paint_requests: self.fixedData(index),
          },
        })
        .then((response) => {
          if (response.status) {
            let detail = self.modelProxy[index];
            let is_editing = !!detail.id;
            let responseData = response.data[0];
            detail.id = responseData.id;
            detail.is_submitted = true;
            detail.is_editing = false;
            detail.pdf_link = responseData.pdf_link;
            detail.order_fabric_excel_link = responseData.order_fabric_excel_link;

            self.modelProxy[index] = detail;
            self.sweetAlertSuccess(
              self.$t(
                is_editing
                  ? "general.successfully_updated"
                  : "general.successfully_created"
              )
            );
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
          self.sweetAlertClose();
          self.is_submitting = false;
        });
    },
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit("update:model", value);
      },
    },
    defaultColor() {
      return this.order.sample_product_informations.find(
        (color) => color.sample_id === color.fabric_request.sample_id
      );
    },
    totalAmount() {
      return function (index) {
        if (!this.modelProxy[index].amount) {
          return 0;
        }
        this.modelProxy[index].total_amount =
          this.modelProxy[index].amount * this.modelProxy[index].price;
        return (+this.modelProxy[index].total_amount).toFixed(0) || 0;
      };
    },
    isValidToSubmit() {
      try {
        let returnData = null;
        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return 1;
          if (!current.fabric_process_ids.length) return 2;
          if (!current.dispatch_location_id) return 3;
          if (!+current.amount) return 4;
          if (!+current.currency_unit_id) return 5;
          if (!+current.expiry_id) return 6;
          if (!+current.price) return 7;
          if (!+current.amount_unit_id) return 8;
          if (!current.description) return 9;
          if (!current.color_ok) return 10;
          if (!current.fabric_type) return 11;
          return true;
        };

        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/fabric-request/${this.orderFabricId}/paint-request/${itemId}/share`;
        };
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
  mounted() {
    if (this.previousModel && this.previousModel.length > 0) {
      this.modelProxy.forEach((model) => {
        model.fabric_supply_type_id = this.fabricProcessingType;

        if (!+model.supplier_company_id) {
          model.supplier_company_id = this.previousModel[0].supplier_company_id;
        }
        if (!model.color_ok) {
          model.color_ok = this.defaultColor.color;
        }
      });
    }
    if(this.$route.query.qr_type=="request-paints"){
      setTimeout(() => {
        let instructionsId = this.$route.query.data.id
        let element = this.$refs["paint_"+instructionsId][0]
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }, 1000);
    }
  },
  watch: {
    tolerances(value) {
      if (value) {
        this.modelProxy.forEach((model) => {
          model.tolerance_amount = this.tolerances
            ? this.tolerances.paint_coefficient
            : 1;
          if (!model.color_ok && !model.id) {
            model.color_ok = this.defaultColor.color;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/generalLayoutFabricType.scss";
/*Mobile End*/
</style>
